<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
		<ion-buttons slot="start">
			<ion-button (click)="popupClose();">
				<ion-icon name="close" color="dark"></ion-icon>
			</ion-button>
		</ion-buttons>
	  <ion-buttons slot="end">
		  <ion-button size="small" class="proj-primary text-semibold" (click)="reset()">{{lang.reset}}</ion-button>
	  </ion-buttons>
		<ion-title>{{lang.filter}}</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content>
  <div class="proj-container-horizontal">
    <div class="proj-container-vertical">
      <h5 class="mb-0">{{lang.date_range}}</h5>
    </div>
  </div>
  <ion-list lines="full" class="mb-2">
    <ion-item>
      <ion-label>{{lang.start_date}}</ion-label>
      <ion-datetime #timePicker [(ngModel)]="mdl_date_start" displayFormat="DD/MM/YYYY" pickerFormat="DD MMM YYYY" max="{{maxData}}"></ion-datetime>
    </ion-item>
    <ion-item>
      <ion-label>{{lang.end_date}}</ion-label>
      <ion-datetime #timePicker [(ngModel)]="mdl_date_end" displayFormat="DD/MM/YYYY" pickerFormat="DD MMM YYYY" max="{{maxData}}"></ion-datetime>
    </ion-item>
  </ion-list>

  <div class="proj-container-horizontal">
    <div class="proj-container-vertical">
      <h5>{{lang.location}}</h5>
      <ion-chip color="primary" outline class="psmall mb-0" *ngFor="let l of location; index as i">
        <ion-label>{{l.kabupaten_name}}</ion-label>
        <ion-icon name="close-outline" (click)="removeLocation(i)"></ion-icon>
      </ion-chip>
      <ion-searchbar [placeholder]="lang.search_city" mode="ios" (ionChange)="cariLokasi($event)" class="ion-no-padding" [(ngModel)]="keyword"></ion-searchbar>

      <ion-list>
          <ion-item *ngFor="let db of data_filtered" lines="none" class="ion-no-padding" (click)="addLocation(db)">
            <ion-label>{{db.kabupaten_name}}</ion-label>
          </ion-item>
      </ion-list>
    </div>
  </div>
</ion-content>

<ion-footer class="shadow ion-no-border">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button (click)="submitFilter()" expand="block" color="primary">{{lang.apply}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
