import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { LanguageService } from 'src/app/services/language.service';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {

  language;

  constructor(
    protected languageService: LanguageService
  )
  {
    this.language = this.languageService.language();
  }

  transform(value: Date | moment.Moment, format: string): any {
    moment.locale(this.language);
    return moment(value).format(format);
  }
}
