import { Component, OnInit } from '@angular/core';
import { NativeService } from 'src/app/services/native.service';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-stuckpage',
  templateUrl: './stuckpage.page.html',
  styleUrls: ['./stuckpage.page.scss'],
})
export class StuckpagePage implements OnInit {
  url : string;
  constructor(
    private nativeService: NativeService,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
   this.url =this.navParams.data.url;
  //  this.openUrl(url);
  }

  // Open browser
  openUrl(url) {
    this.nativeService.openUrl(url);
  }

}
