<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
		<ion-buttons slot="start">
			<ion-button (click)="popupClose();">
				<ion-icon name="close" color="dark"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
  <div class="proj-container-vertical">
    <div class="proj-container-horizontal">
      <div class="d-flex ion-align-items-center">
        <ion-thumbnail class="mr-2">
          <img [src]="product_image">
        </ion-thumbnail>
        <div class="flex-grow">
          <h5 class="product_name mb-half">{{product_name}}</h5>
          <p class="text-semibold mb-0">Rp {{product_price | number}}</p>
        </div>
      </div>

      <div class="proj-textlight text-semibold">{{product_category}}</div>
      <div class="mb-2">
        <ion-button *ngFor="let db of product_model" class="btn_size" [style]="db.selected" size="small"
          (click)="pilih(db.product_id)">
          {{db.product_model_label}}
        </ion-button>
      </div>

      <table class="mb-2" width="100%">
        <tr>
          <td class="proj-textlight text-semibold" style="text-align: left;">
            {{lang.total}}
          </td>
          <td class="text-bold">
            Rp&nbsp;{{product_price | number}}
          </td>
          <td width="1">
            <div class="minus">
              <ion-icon name="remove" (click)="qty_minus()"></ion-icon>
            </div>
          </td>
          <td width="1">
            <div class="text-bold" style="min-width:32px;text-align: center;">{{qty}}</div>
          </td>
          <td width="1">
            <div class="plus">
              <ion-icon name="add" (click)="qty_plus()"></ion-icon>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</ion-content>

<ion-footer class="box-light" mode="ios">
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-button expand="block" fill="clear" (click)="addToCart(1)">{{lang.add_to_cart}}
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button expand="block" (click)="addToCart(2)">{{lang.buy_now}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
