import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { EventService } from 'src/app/services/event.service';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-popup-order',
  templateUrl: './popup-order.page.html',
  styleUrls: ['./popup-order.page.scss'],
})
export class PopupOrderPage implements OnInit {

  id: number;
  cart_id: number;
  radio_order: string;
  lang: any = {
    cart: {}
  };

  constructor(
    public eventService: EventService,
    public modalController: ModalController,
    public navParams: NavParams,
    private languageService: LanguageService
  )
  {
    this.languageService.loadPageLanguage('shop').subscribe(res => { this.lang = res } );
  }

  ngOnInit() {
    this.id = this.navParams.get('id');
    this.cart_id = this.navParams.get('cart_id');
    this.radio_order = '' + this.navParams.get('delivery_option');
  }

  popupClose() {
    this.modalController.dismiss();
  }


  confirm() {
    this.eventService.pubDeliveryOption({
      id: this.id,
      cart_id: this.cart_id,
      delivery_option: this.radio_order,
    });

    this.modalController.dismiss();
  }

}
