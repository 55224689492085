import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { EventService } from '../../../services/event.service';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-popup-sort',
  templateUrl: './popup-sort.page.html',
  styleUrls: ['./popup-sort.page.scss'],
})
export class PopupSortPage implements OnInit {

  mdl_sort :any;

  filter :any;
  lang: any = {};

  constructor(
    private eventService: EventService,
    public modalController: ModalController,
    public navParams: NavParams,
    private languageService: LanguageService
  )
  {
    this.languageService.loadPageLanguage('dashboard').subscribe(res => { this.lang = res} );
  }

  ngOnInit() {
    this.filter = this.navParams.get('filter');
    this.mdl_sort = this.filter.sortby;
  }

  popupClose() {
    this.eventService.pubFilter({
      date_start: this.filter.date_start,
      date_end: this.filter.date_end,
      kabupaten_id: this.filter.kabupaten_id,
      sortby: this.mdl_sort,
      location: this.filter.location
    });
    this.modalController.dismiss();
  }

}
