import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-checkout-info',
  templateUrl: './checkout-info.page.html',
  styleUrls: ['./checkout-info.page.scss'],
})
export class CheckoutInfoPage implements OnInit {
  constructor(
    public modalController: ModalController
  ) { }
 	
  ngOnInit() {
  }
  async closeClockinModal() {
    await this.modalController.dismiss();
  }

}
