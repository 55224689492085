export interface MyEvent {
	event_id: number,
	event_name: string,
	event_image: string,
	event_location: string,
	event_start_date: string,
	event_end_date: string
}

export class User {
	public constructor(
		public member_id: string,
		public member_email: string,
		public member_username: string,
		public member_fullname: string,
		public member_phone: string,
		public member_gender: string,
		public member_company_name: string,
		public member_image: string,
    public member_company_position: string,
		private _token: string
	) {}

	get token() {
		return this._token;
	}
}
