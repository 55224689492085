<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon name="arrow-back-outline" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{name}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="proj-container-vertical t-1 b-2">
    <div class="proj-container-horizontal">
      <div [innerHTML]="text_area_description"></div>
    </div>
  </div>
</ion-content>
