<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
		<ion-buttons slot="start">
      <ion-back-button text="" icon="arrow-back-outline" color="dark"></ion-back-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
  <div class="defaultpage">
		<img src="assets/media/statement-stuck.svg">
		<h4 class="text-semibold mb-1">Update available</h4>
		<p>A new version is available. Update the app to continue</p>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div class="proj-container-vertical">
      <div class="proj-container-horizontal">
        <ion-button (click)="openUrl(url)" expand="block" class="ion-no-margin" color="primary">Update</ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
