<ion-header>
  <ion-toolbar mode="ios">
		<ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Reservation</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <br>
  <div *ngFor="let q of questions;let i = index">
      <div class="proj-container-horizontal">
        <p class="h5 text-regular">{{i+1}}. {{q.rsvp_question}}</p>
      </div>
      <!-- TYPE RADIO -->
      <div class="proj-container-vertical t-0" *ngIf="q.rsvp_answer_type == 1">
        <ion-list lines="none">
          <ion-radio-group [(ngModel)]="result[i].result">
            <ion-item *ngFor="let option of q.option">
              <ion-label>{{option.rsvp_option_label}}</ion-label>
              <ion-radio slot="start" [value]="option.rsvp_option_value"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
      <!-- TYPE TEXTBOX -->
      <div class="proj-container-vertical t-0" *ngIf="q.rsvp_answer_type == 2">
        <div class="proj-container-horizontal d-block mb-2">
          <ion-textarea [(ngModel)]="result[i].result" autoGrow placeholder="Tuliskan Jawaban Anda"></ion-textarea>
        </div>
      </div>
      <!-- TYPE CHECKBOX -->
      <div class="proj-container-vertical t-0" *ngIf="q.rsvp_answer_type == 3">
        <ion-item-group>
          <ion-item *ngFor="let option of q.option" lines="none">
            <ion-label>{{option.rsvp_option_label}}</ion-label>
            <ion-checkbox slot="start" [(ngModel)]="option.selected"></ion-checkbox>
          </ion-item>
        </ion-item-group>
      </div>
  </div>
</ion-content>

<ion-footer class="shadow ion-no-border box-light">
	<ion-toolbar class="ion-no-padding">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button type="submit" (click)="submitRSVP()" color="primary" expand="block" size="default">Submit</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
