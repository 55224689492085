// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  lang: 'en',
  baseUrl: 'https://event.gbigilgal.org/lumen-api/public',
  baseUrlShare: 'https://event.gbigilgal.org/',
  API2Url: 'https://event.gbigilgal.org/api',
  API3Url: 'https://event.gbigilgal.org/unictive-location/api',
  xendit: {
    apiKey: 'xnd_public_production_OPAYvRG7s8sDuBwR11XGDGuqpdR2E5YtfLuh2pMxDp2wslZERmSSv7AdyaNJt',
    apiKeyDev: 'xnd_public_development_9aeVRqV1KRNEhIq2vOishO3tSM1e36oJFybIpVjQDmfpLGwCUCQfiDwMamxu'
  },
  // firebase : {
  //   apiKey: "AIzaSyCCajf7FqLVMLJx3WknsXDMwhHf5SWYopI",
  //   authDomain: "nextgen-conference-online.firebaseapp.com",
  //   databaseURL: "https://nextgen-conference-online.firebaseio.com",
  //   projectId: "nextgen-conference-online",
  //   storageBucket: "nextgen-conference-online.appspot.com",
  //   messagingSenderId: "387384923030",
  //   appId: "1:387384923030:web:e175baca69f1893e7883b7",
  //   measurementId: "G-DQQC73GRJY"
  // },
  firebase : {
    apiKey: "AIzaSyAw5xcEwKSP6P_lXSKyDf1HDv6IHRsPAAk",
    authDomain: "events-54651.firebaseapp.com",
    databaseURL: "https://events-54651-default-rtdb.firebaseio.com",
    projectId: "events-54651",
    storageBucket: "events-54651.appspot.com",
    messagingSenderId: "181081001022",
    appId: "1:181081001022:web:2e61d7274d16d39969556f",
    measurementId: "G-LP89QQENQC"
  },
  zoom : {
    apiKey: "JRPsXrdNX4qzr9B8Rg77GOUSsSl2iEpiOtbO",
    apiSecret: "JGGStsZLRB4XCdh5oiuOO5AAo6LnZGsoY1Ck"
  },
  zoomweb : {
    apiKey: "jj_mev97TVSjc98mY7Qohg",
    apiSecret: "JwZmABmAlslp9twCtM4MoRE6HJGUWNDPeiGa"
  },
  baseUrl_location: 'https://event.gbigilgal.org/unictive-location/api/get_data',
  mainEventFolder: '/event_app',
  deeplink_domain: '.org'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
