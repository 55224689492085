<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
		<ion-buttons slot="start">
			<ion-button (click)="popupClose();">
				<ion-icon name="close" color="dark"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-title>{{lang.sort}}</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content>
  <div class="proj-container-vertical">
    <ion-list lines="none">
      <ion-radio-group [(ngModel)]="mdl_sort">
        <ion-item>
          <ion-label>{{lang.new}}</ion-label>
          <ion-radio slot="start" value="new"></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>{{lang.popular}}</ion-label>
          <ion-radio slot="start" value="popular"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </div>
</ion-content>

<ion-footer class="shadow ion-no-border">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button (click)="popupClose()" expand="block" color="primary">{{lang.save}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
