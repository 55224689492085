import { Component, OnInit, Input } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { EventService } from 'src/app/services/event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.page.html',
  styleUrls: ['./rsvp.page.scss'],
})
export class RSVPPage implements OnInit {
  @Input() questions;
  @Input() event_id;
  @Input() ticket_id;
  result: any[] = [];

  constructor(
    private modalCtrl: ModalController,
    private eventService: EventService,
    private loadingCtrl: LoadingController,
    private router: Router,
    private alertCtrl: AlertController,

  ) { }

  ngOnInit() {
    this.questions.forEach((val, i) => {
      let result = {
        question_id: val.rsvp_id,
        answer_type: val.rsvp_answer_type,
        result: null
      };
      this.result.push(result);
    });
  }

  submitRSVP() {
    let invalid;
    let answer = [];
    // mapping answer
    this.questions.forEach((val, i) => {
      if(val.rsvp_answer_type == 3) {
        let selectedOpts = [];
        selectedOpts = val.option.filter((opt) => {
          return opt.selected==true;
        });
        this.result[i].result = selectedOpts;
      }
    });

    this.result.forEach((val) => {
      if(val.result == null || val.result.length == 0 || val.result == '') {
        invalid = true;
      }

      if(val.answer_type == 3) {
        val.result.forEach((v) => {
          let res = {
            question_id: val.question_id,
            answer_type: val.answer_type,
            result: v.rsvp_option_value
          };
          answer.push(res);
        });
      } else {
        answer.push(val);
      }
    });
    // end of mapping answer

    // must answer all question, else invalid
    if(invalid) {
      this.showAlert('Silahkan mengisi seluruh pertanyan sebelum klik button Submit');
      return;
    }

    //submit RSVP
    let params = {
      event_id: this.event_id,
      ticket_id: this.ticket_id,
      questions: answer
    };
    this.loadingCtrl.create({keyboardClose:true})
    .then(loading => {
      loading.present();
      this.eventService.submitRSVP(params)
      .subscribe(
        res => {
          loading.dismiss();
          this.modalCtrl.dismiss();
          window.location.reload();
        }
      );
    });
    // console.log(this.result);
    // console.log(answer);
  }

  closeModal() {
    this.modalCtrl.dismiss();
    this.router.navigateByUrl('/home-tabs/dashboard', { replaceUrl: true });
  }

  showAlert(message: string) {
    this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'custom-alert',
      message: message,
      buttons: ['OK']
    })
      .then(alertEl => alertEl.present());
  }

}
