import { NgModule,LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
registerLocaleData(localeId);

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Camera } from '@ionic-native/camera/ngx';

import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CheckoutInfoPageModule } from './transaction/buyticket/checkout-info/checkout-info.module';
import { TicketcodePageModule } from './profiles/ticketcode/ticketcode.module';
import { TicketsuccessPageModule } from './profiles/ticketsuccess/ticketsuccess.module';
import { ModalverifyphonePageModule } from './profiles/modalverifyphone/modalverifyphone.module';
import { ModalverifyemailPageModule } from './profiles/modalverifyemail/modalverifyemail.module';
import { ModalquestionPageModule } from './event/session/sessiondetail/modalquestion/modalquestion.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from "@angular/fire/firestore";
//import * as firebase from 'firebase'
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';
import 'firebase/auth';

import * as $ from 'jquery';
import { Zoom } from '@ionic-native/zoom/ngx';
import { TimesagoPipe } from './pipes/timesago.pipe';
import { YoutubePipe } from './youtube.pipe';
import { InitialPipe } from './pipes/initial.pipe';
import { SharedModule } from './shared/shared/shared.module';

import { IonicStorageModule } from '@ionic/storage';
import { Network } from '@ionic-native/network/ngx';
import { StuckpagePage } from './general/stuckpage/stuckpage.page';
import { TutorialPage } from './general/tutorial/tutorial.page';
import { RSVPPage } from './event/rsvp/rsvp.page';
import { PreviewImagePage } from './event/preview-image/preview-image.page';
import { PopupFilterPage } from './home/dashboard/popup-filter/popup-filter.page';
import { PopupSortPage } from './home/dashboard/popup-sort/popup-sort.page';
import { ModalquestionPage } from './event/session/sessiondetail/modalquestion/modalquestion.page';
import { TextareaPage } from './event/textarea/textarea.page';
import { TicketcodePage } from './profiles/ticketcode/ticketcode.page';
import { TicketsuccessPage } from './profiles/ticketsuccess/ticketsuccess.page';
import { PopupLocationPage } from './home/dashboard/popup-location/popup-location.page';
import { ModalverifyphonePage } from './profiles/modalverifyphone/modalverifyphone.page';
import { ModalverifyemailPage } from './profiles/modalverifyemail/modalverifyemail.page';
import { SearchLocationPage } from './profiles/manage-address/search-location/search-location.page';
import { TransactionDetailComponent } from './transaction/buyticket/changepayment/transaction-detail/transaction-detail.component';
import { PaymentAttributeComponent } from './transaction/buyticket/payment-method/payment-attribute/payment-attribute.component';
import { DetailTransactionComponent } from './transaction/buyticket/payment-method/detail-transaction/detail-transaction.component';
import { PopupSizePage } from './transaction/shop/popup-size/popup-size.page';
import { PopupOrderPage } from './transaction/shop/popup-order/popup-order.page';
import { PopupShippingPage } from './transaction/shop/popup-shipping/popup-shipping.page';
import { DetailTransactionShopPage } from './transaction/shop/payment-method-shop/detail-transaction-shop/detail-transaction-shop.page';
import { PopupQtyPage } from './transaction/shop/popup-qty/popup-qty.page';

firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent, YoutubePipe],
  entryComponents: [StuckpagePage, TutorialPage, RSVPPage, PreviewImagePage,
    PopupFilterPage, PopupSortPage, ModalquestionPage, TicketcodePage, TicketsuccessPage,
    PopupLocationPage, ModalverifyphonePage, ModalverifyemailPage, SearchLocationPage,
    TransactionDetailComponent, PaymentAttributeComponent, DetailTransactionComponent,
    PopupSizePage, PopupOrderPage, PopupShippingPage, DetailTransactionShopPage, PopupQtyPage, TextareaPage],

  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    //ServiceWorkerModule,
    CheckoutInfoPageModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule
  ],
  providers: [
    BarcodeScanner,
    Geolocation,
		LocationAccuracy,
    AndroidPermissions,
    AppVersion,
    EmailComposer,
    Camera,
    File,
    FileTransfer,
    FileOpener,
    Zoom,
    StreamingMedia,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'id-ID' },
    Network
  ],
  bootstrap: [AppComponent]
})
export class AppModule {


}
