<ion-content>
  <div class="defaultpage">
		<img src="assets/media/statement-scansuccess.svg">
		<h4 class="text-semibold mb-1">Scan Successful</h4>
		<p>Music Blast Live - Ticket Day 1</p>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button fill="clear" expand="block" size="default" (click)="closeModal()">See later</ion-button>
        </ion-col>
        <ion-col>
          <ion-button color="primary" expand="block" size="default">See event</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
