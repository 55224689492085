<ion-header class="ion-no-border">
	<ion-toolbar mode="ios">
		<ion-buttons slot="start">
			<ion-button (click)="closeModal();">
				<ion-icon name="close" color="dark"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
  <div class="defaultpage">
		<img src="assets/media/statement-email.svg">
		<h4 class="text-semibold mb-1">Verification link sent!</h4>
		<p>Check your email to verify your new email address.</p>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div class="proj-container-vertical">
      <div class="proj-container-horizontal">
        <ion-button type="submit" expand="block" class="ion-no-margin" color="primary" (click)="closeModal();">Verify now</ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
