import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-ticketsuccess',
  templateUrl: './ticketsuccess.page.html',
  styleUrls: ['./ticketsuccess.page.scss'],
})
export class TicketsuccessPage implements OnInit {

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

}
