import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.page.html',
  styleUrls: ['./tutorial.page.scss'],
})
export class TutorialPage implements OnInit {
  @ViewChild('slides') slides: IonSlides;
  nextButton = 'Next';
  slideOpts = {
    slidesPerView: 'auto',
    spaceBetween: 20
  };

  data = [
    {
      img: 'assets/media/tutorial-explore-event.svg',
      title: 'Explore event',
      detail: 'Discover your favorite online or offline event'
    },
    {
      img: 'assets/media/tutorial-buy-ticket.svg',
      title: 'Buy ticket',
      detail: 'Get e-ticket to your preferred event'
    },
    {
      img: 'assets/media/tutorial-shop-online.svg',
      title: 'Shop online',
      detail: 'Find your favorite merchandise or digital file and pay it online!'
    },
    {
      img: 'assets/media/tutorial-timeline.svg',
      title: 'Timeline',
      detail: 'Share your thoughts and keep in touch with everyone'
    },
    {
      img: 'assets/media/tutorial-event-information.svg',
      title: 'Event information',
      detail: 'Get information about session, exhibitor and anything about the event'
    },
    {
      img: 'assets/media/tutorial-you-are-good-to-go.svg',
      title: 'You are good to go',
      detail: 'Ready to dig our apps? Start your experience with us'
    }
  ]

  constructor(
    public modalController: ModalController,

  ) { }

  ngOnInit() {
  this.nextButton = 'Next';

  }

  next() {
    this.slides.isEnd().then((data) => {
      if (data) {
        this.modalController.dismiss();
      } else {
        this.slides.slideNext();
      }
    });
  }

  prev() {
    this.slides.slidePrev();
  }

  skip() {
    this.modalController.dismiss();
  }

  finish() {
    this.nextButton = 'Finish';
  }
}
