import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-payment-attribute',
  templateUrl: './payment-attribute.component.html',
  styleUrls: ['./payment-attribute.component.scss'],
})
export class PaymentAttributeComponent implements OnInit {
  @Input() payment_attribute;
  @Input() payment;
  lang: any = {};

  constructor(
    private modalController: ModalController,
    private languageService: LanguageService
  )
  {
    this.languageService.loadPageLanguage('payment').subscribe(res => { this.lang = res } );
  }

  ngOnInit() {
    this.payment_attribute.forEach(val => {
      if(val.payment_attribute_type == 1) {
        val.input_type = 'text';
      } else if(val.payment_attribute_type == 2) {
        val.input_type = 'tel';
      } else if(val.payment_attribute_type == 3) {
        val.input_type = 'text';
      } else if(val.payment_attribute_type == 4) {
        val.input_type = 'password';
      }

      val.value = '';
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  next(form: NgForm) {
    let data;
    //if payment category = instant payment / e-money
    if(this.payment.payment_category == 3) {
      let phone = form.value.phone;
      data = {
        phone: phone
      };
    } else if(this.payment.payment_category == 2) {
      let card_number = form.value.card_number;
      let card_exp_month = form.value.card_exp_month;
      let card_exp_year = form.value.card_exp_year;
      let card_cvn = form.value.card_cvn;
      data = {
        card_number: card_number,
        card_exp_month: card_exp_month,
        card_exp_year: card_exp_year,
        card_cvn: card_cvn,
      }
    }
    this.modalController.dismiss({
      data: data
    },'submit');
  }

}
