<ion-header class="ion-no-border" translucent>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="closeClockinModal();">
          <ion-icon name="close" color="dark"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

<ion-content>
      <!-- Payment with ovo -->
      <div class="proj-container-vertical t-1 b-1">
        <div class="proj-container-horizontal">
            <div class="d-flex">
                <img src="assets/media/icon-ovo.svg"/>
                <h4 class="ml-1 mb-0">OVO</h4>
            </div>
        </div>
    </div>
    <div class="proj-container-vertical t-2 b-1">
        <div class="proj-container-horizontal">
          <p class="psmall text-light mb-0">Fill in your OVO phone number</p>
            <ion-list lines="full" class="ion-no-margin ion-no-padding">
                <ion-item class="nopadding">
                <ion-input required type="text"></ion-input>
                </ion-item>
            </ion-list>
        </div>
    </div>


      <!-- Payment with ovo -->
      <!-- <div class="proj-container-vertical t-1 b-1">
        <div class="proj-container-horizontal">
            <div class="d-flex">
                <img src="assets/media/icon-debit.svg"/>
                <h3 class="ml-1 mb-0">Debit/Credit Card</h3>
            </div>
        </div>
    </div>
    <div class="proj-container-vertical t-2 b-1">
        <div class="proj-container-horizontal">
            <img src="assets/media/icon-debitkredit.svg"/>
        </div>
    </div>
    <div class="proj-container-vertical t-2 b-1">
        <div class="proj-container-horizontal">
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
                <ion-item class="nopadding">
              <ion-input required type="text" placeholder="Cart Number"></ion-input>
            </ion-item>
                <ion-item class="nopadding">
                <ion-input required type="text" placeholder="Full Name"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input required type="text" placeholder="Exp Date"></ion-input>
                <ion-input required type="text" placeholder="CCV/CCV"></ion-input>
            </ion-item>
        </ion-list>
      </div>
    </div>  -->

</ion-content>
<ion-footer class="ion-no-border">
	<ion-toolbar class="ion-no-padding">
		<ion-grid>
			<ion-row>
				<ion-col>
						<ion-button routerLink="/email-template" expand="block" class="ion-no-margin" color="primary">Next</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
  </ion-toolbar>
</ion-footer>
