import { Component, NgZone, ViewChild } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Location } from "@angular/common";

import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';

import { environment } from 'src/environments/environment';
import { Zoom } from '@ionic-native/zoom/ngx';

const { App } = Plugins;
const { SplashScreen } = Plugins;

import { IonRouterOutlet, AlertController } from '@ionic/angular';
import { NativeService } from './services/native.service';
import { AuthService } from './services/auth.service';
import { NetworkService, ConnectionStatus } from './services/network.service';
import { OfflineManagerService } from './services/offline-manager.service';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;
  lang: any = {};

  constructor(
    private location: Location,
    private platform: Platform,
    private zoomService: Zoom,
    private router: Router,
    private alertCtrl: AlertController,
    private zone: NgZone,
    private nativeService: NativeService,
    private authService: AuthService,
    private offlineManager: OfflineManagerService,
    private networkService: NetworkService,
    private languageService: LanguageService
  ) {
    this.languageService.loadPageLanguage('app').subscribe(res => { this.lang = res } );
    this.initializeApp();
  }

  initializeApp() {

    this.platform.ready().then(() => {
      console.log("ready_platform");
      // SplashScreen.hide();
      this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
        if (status == ConnectionStatus.Online) {
          this.offlineManager.checkForEvents().subscribe();
        }
      });
    });

    // if ('serviceWorker' in navigator){
    //   navigator.serviceWorker.register('/sw.js').then(function(registration){
    //     console.log('service worker registration succeeded:',registration);
    //   }, function(error){
    //     console.log('service worker registration failed:',error);
    //   });
    // }
    // else{
    //   console.log('service workers are not supported.');
    // }

    this.platform.backButton.subscribe(() => {
      //this.location.back();
      if (this.routerOutlet && this.routerOutlet.canGoBack()) {
        this.routerOutlet.pop();
      } else {
        this.presentAlertConfirm()
      }

      // else if (this.router.url === '/home-tabs/dashboard') {
      //   navigator['app'].exitApp()
      // }


    });

    this.zoomService.initialize(environment.zoom.apiKey, environment.zoom.apiSecret)
      .then((success: any) => console.log(success))
      .catch((error: any) => console.log(error));

    this.nativeService.fcmInit()
      .then((resp) => {
        this.authService.setFcmToken(resp);
      });

    this.languageService.getLanguageSetting()
      .then((resp) => {
        if(resp.value == null) {
          this.languageService.setLanguage('en');
        } else {
          this.languageService.setLanguage(resp.value);
        }
      });

    /*
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        console.log("appUrlOpen");
        console.log(JSON.stringify(data));
        const slug = data.url.split(environment.deeplink_domain).pop();
        if (slug) {
          console.log(slug);
          //this.router.navigateByUrl(slug);
          if(slug.includes("checkout/success-ticket")){
            var id = slug.split("/").pop();
            this.router.navigateByUrl("/checkout-successful/"+id);
          } if(slug.includes("checkout/success-shop")){
            var id = slug.split("/").pop();
            this.router.navigateByUrl("/checkout-successful-shop/"+id);
          }
          else this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
    */
  }

  async presentAlertConfirm() {
    const alert = await this.alertCtrl.create({
      header: this.lang.confirm_header,
      message: this.lang.confirm_exit_app,
      buttons: [
        {
          text: this.lang.cancel,
          role: 'cancel'
        }, {
          text: this.lang.okay,
          handler: () => {
            navigator['app'].exitApp()
          }
        }
      ]
    });

    await alert.present();
  }
}
