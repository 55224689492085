<ion-header class="ion-no-border">
  <ion-toolbar color="dark" mode="ios">
    <ion-title>Preview</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal();">
				<ion-icon name="close" color="light"></ion-icon>
			</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="dark">
  <ion-grid>
    <ion-row>
      <!-- <ion-col *ngFor="let db of eventDetail.event_has_image;let i = index" size="6">
        <div *ngIf="i < 3" class="thumbs" [style.backgroundImage]="'url(' + db.event_has_image + ')'">
        </div>
      </ion-col> -->
      <ion-col>
        <img [src]="url" class="image-block">
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
