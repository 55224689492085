import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, map, take, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from } from 'rxjs';
import { User } from '../models/user.model';
import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LanguageService } from './language.service';
import * as firebase from 'firebase/app';
import 'firebase/database';
export const myFirebaseRedirect = firebase.initializeApp(environment.firebase, 'redirectFirebase');
export const chatRoomDefault = '/kelolain/multiple_login/';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user = new BehaviorSubject<User>(null);
  private _fcm = new BehaviorSubject<String>(null);
  private myFirebaseListener;
  language;

  constructor(
    private http: HttpClient,
    private router: Router,
    private languageService: LanguageService
  ) {
    this.language = this.languageService.language();
  }

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user.token;
        } else {
          return false;
        }
      })
    );
  }

  get token() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.token;
        } else {
          return '';
        }
      })
    );
  }

  get user() {
    return this._user.asObservable();
  }

  login(email: string, password: string, fcm_token: any = '', uuid: string, os: string, os_version: string, model: string) {
    let body = new FormData();
    body.append('email', email);
    body.append('password', password);
    if (fcm_token != '') {
      body.append('fcm_token', fcm_token);
    }
    body.append('uuid', uuid);
    body.append('os', os);
    body.append('os_version', os_version);
    body.append('model', model);
    body.append('language', this.language);

    return this.http.post<any>(
      `${environment.baseUrl}/api/login`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        // tap(this.setUserData.bind(this))
      );
  }


  loginVIP(qrcode: string) {
    let body = new FormData();
    body.append('qrcode', qrcode);

    return this.http.post<any>(
      `${environment.baseUrl}/api/event/scan_invitation_code`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        // tap(this.setUserData.bind(this))
      );
  }

  loginGoogle(email: string, name: string, phone: string, hash_code: string, fcm_token: any = '', uuid: string, os: string, os_version: string, model: string) {
    let body = new FormData();
    body.append('email', email);
    body.append('member_fullname', name);
    body.append('member_phone', phone);
    body.append('hash_code', hash_code);
    if (fcm_token != '') {
      body.append('fcm_token', fcm_token);
    }
    body.append('uuid', uuid);
    body.append('os', os);
    body.append('os_version', os_version);
    body.append('model', model);
    body.append('language', this.language);

    return this.http.post<any>(
      `${environment.baseUrl}/api/googlesignin`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        //tap(this.setUserData.bind(this))
      );
  }

  appleLogin(email: string, fullname: string, apple_id: string, hash_code: string, fcm_token: any = '', uuid: string, os: string, os_version: string, model: string) {
    let body = new FormData();
    if (email != '' && email != 'null' && email != null) {
      body.append('member_email', email);
    }
    if (email != '' && email != 'null' && email != null) {
      body.append('member_fullname', fullname);
    }
    body.append('apple_id', apple_id);
    body.append('hash_code', hash_code);
    if (fcm_token != '') {
      body.append('fcm_token', fcm_token);
    }
    body.append('uuid', uuid);
    body.append('os', os);
    body.append('os_version', os_version);
    body.append('model', model);
    body.append('language', this.language);

    return this.http.post<any>(
      `${environment.baseUrl}/api/applesignin`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        //tap(this.setUserData.bind(this))
      );
  }

  setUserData(data: any) {
    console.log(data);
    const user = new User(
      data.member_id,
      data.member_email,
      data.member_username,
      data.member_fullname,
      data.member_phone,
      data.member_gender,
      data.member_company_name,
      data.member_image,
      data.member_company_position,
      data.token
    );
    if (data.token2) {
      let redirectData = {
        "member_id": data.member_id,
        "token2": data.token2
      }
      Plugins.Storage.set({ key: 'token2', value: JSON.stringify(redirectData) });
    }
    this._user.next(user);

    const authData = JSON.stringify({
      member_id: data.member_id,
      member_email: data.member_email,
      member_username: data.member_username,
      member_fullname: data.member_fullname,
      member_phone: data.member_phone,
      member_gender: data.member_gender,
      member_company_name: data.member_company_name,
      member_image: data.member_image,
      member_company_position: data.member_company_position,
      token: data.token
    });

    Plugins.Storage.set({ key: 'authData', value: authData });

    Plugins.Storage.get({ key: 'token2' }).then((val) => {
      console.log(val);
      this.callForceRedirectFunction(val);
    });
  }
  callForceRedirectFunction(dataToken) {
    var tokenData = JSON.parse(dataToken.value);
    if (tokenData) {
      let myLoginToken = tokenData.token2;
      let getThisIonic = this;
      this.myFirebaseListener = myFirebaseRedirect.database().ref(chatRoomDefault + tokenData.member_id);
      this.myFirebaseListener.on('value', (snapshot) => {
        let myValue = snapshot.val();
        console.log(myValue)
        if (myLoginToken != myValue.token) {
          ///   console.log('Token tidak sama');
          // if (this.myFirebaseListener) {
          //   this.myFirebaseListener.off();
          // }
          // this._user.next(null);
          // Plugins.Storage.remove({ key: 'authData' });
          // Plugins.Storage.remove({ key: 'token2' });
          // this.router.navigateByUrl('/dual-login');
          this.logout();
        } else {
          //  console.log('Token Sama');
        }
      });
    }
  }
  autoLogin() {
    return from(Plugins.Storage.get({ key: 'authData' })).pipe(
      map(storedData => {
        if (!storedData || !storedData.value) {
          return null;
        }
        const parsedData = JSON.parse(storedData.value) as {
          member_id: string;
          member_email: string;
          member_username: string;
          member_fullname: string;
          member_phone: string;
          member_gender: string;
          member_company_name: string;
          member_image: string;
          member_company_position: string;
          token: string;
        };

        const user = new User(
          parsedData.member_id,
          parsedData.member_email,
          parsedData.member_username,
          parsedData.member_fullname,
          parsedData.member_phone,
          parsedData.member_gender,
          parsedData.member_company_name,
          parsedData.member_image,
          parsedData.member_company_position,
          parsedData.token
        );
        return user;
      }),
      tap(user => {
        if (user) {
          this._user.next(user);
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }

  logout() {
    if (this.myFirebaseListener) {
      this.myFirebaseListener.off();
    }
    this._user.next(null);
    Plugins.Storage.remove({ key: 'authData' });
    Plugins.Storage.remove({ key: 'token2' });
    this.router.navigateByUrl('/home-tabs');
    window.location.reload()
  }

  get fcm() {
    return this._fcm.asObservable();
  }

  setFcmToken(token) {
    this._fcm.next(token);
  }
}
