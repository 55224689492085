<ion-header class="ion-no-border" translucent>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal();">
        <ion-icon slot="icon-only" name="close-outline" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- Payment with Bank Transfer -->
  <div class="proj-container-vertical">
    <div class="proj-container-horizontal">
      <h5 class="text-semibold">{{lang.detail_transaction}}</h5>
      <div *ngFor="let detail of shopTransaction">
        <div class="d-flex">
          <div class="flex-grow mr-1">
            <h6 class="psmall mb-half">{{detail.qty}} x {{detail.product_name}}</h6>
            <ion-badge color="light" class="pxsmall text-regular mb-half mr-half">{{detail.product_model_label}}
            </ion-badge>
            <ion-badge color="light" class="pxsmall text-regular mb-half mr-half">{{detail.delivery_option_name}}
            </ion-badge>
            <p class="psmall" *ngIf="detail.product_weight">{{detail.product_weight}} Kg</p>

          </div>
          <div class="ml-auto">
            <h6 class="psmall text-semibold">{{detail.product_price_after_discount|currency:'IDR'}}</h6>
          </div>
        </div>
      </div>
      <hr />
      <div class="d-flex psmall mb-1">
        <p class="mb-0">{{lang.trans_detail.subtotal}}</p>
        <div class="ml-auto text-semibold">
          <p class="mb-0">Rp. {{totalPrice|number}}</p>
        </div>
      </div>
      <div class="d-flex psmall mb-1" *ngIf="shippingPrice>0">
        <p class="mb-0">{{lang.trans_detail.shipping_charge}}</p>
        <div class="ml-auto text-semibold">
          <p class="mb-0">Rp. {{shippingPrice|number}}</p>
        </div>
      </div>
      <div class="d-flex psmall mb-1" *ngIf="serviceCharge>0">
        <p class="mb-0">{{lang.trans_detail.service_charge}}</p>
        <div class="ml-auto text-semibold">
          <p class="mb-0">Rp. {{serviceCharge|number}}</p>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-no-padding">
    <ion-grid>
      <ion-row>
        <ion-col>
          <p class="mb-0">{{lang.trans_detail.total}}</p>
        </ion-col>
        <ion-col>
          <ion-text color="primary">
            <h5 class="ion-text-right mb-0"> {{(totalPrice+shippingPrice+serviceCharge)|currency:'IDR'}}</h5>
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
