import { Component, NgZone, OnInit, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
const { Geolocation } = Plugins;

import { NativeService } from '../../../services/native.service';
import { LanguageService } from 'src/app/services/language.service';

declare var google;


@Component({
  selector: 'app-search-location',
  templateUrl: './search-location.page.html',
  styleUrls: ['./search-location.page.scss'],
})
export class SearchLocationPage implements OnInit {
  @Input() type;

  myLocation: any;
  lat: any;
  lng: any;
  data: any;

  map: any;

  mdl_cari: any;
  lang: any = {};

  constructor(
    public modalController: ModalController,
    public platform: Platform,
    private router: Router,
    private nativeService: NativeService,
    private zone: NgZone,
    private languageService: LanguageService
  )
  {
    this.languageService.loadPageLanguage('manage-address').subscribe(res => { this.lang = res } );
  }

  async ngOnInit() {
    this.zone.run(async () => {
      /*const coordinates = await Geolocation.getCurrentPosition();
      this.lat = coordinates.coords.latitude;
      this.lng = coordinates.coords.longitude;*/
      this.nativeService.getCurrentLocation().then((resp) => {
        this.myLocation = resp;
        this.lat = this.myLocation.lat;
        this.lng = this.myLocation.lon;
        console.log(JSON.stringify(resp));
      }, (error) => {
        console.log(JSON.stringify(error))
      });
    });
  }

  async get_current_loc() {
    this.zone.run(async () => {
      if (this.type == 1) {
        this.router.navigate(['/manage-address/map-address', {
          lat: this.lat,
          lng: this.lng,
        }]);
      } else {
        this.router.navigateByUrl('/manage-address/map-pin/' + null + '/' + null + '/' + this.lat + '/' + this.lng + '/' + null);
      }
    });

    this.modalController.dismiss();
  }

  cari(ev: any) {
    let GoogleAutocomplete = new google.maps.places.AutocompleteService();

    if (ev.target.value == '' || ev.target.value.length < 3) {
      this.data = [];
      return;
    } else {
      GoogleAutocomplete.getPlacePredictions({
        input: ev.target.value, componentRestrictions: { country: 'id' },
      }, (predictions, status) => {
        this.data = [];
        this.zone.run(() => {
          // console.log(predictions);
          predictions.forEach((prediction) => {
            this.data.push(prediction);
          });
        });
      });
    }
  }

  get_selected_loc(val_place_id, val_name) {
    if (this.type == 1) {
      this.router.navigate(['/manage-address/map-address', {
        place_id: val_place_id,
        place_name: val_name,
      }]);
    } else {
      this.router.navigateByUrl('/manage-address/map-pin/' + null + '/' + null + '/' + null + '/' + null+ '/' + val_place_id);
    }

    this.modalController.dismiss();
  }

  popupClose() {
    this.modalController.dismiss();
  }

}
