import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.page.html',
  styleUrls: ['./textarea.page.scss'],
})
export class TextareaPage implements OnInit {
  @Input() content;
  @Input() name;
  text_area_description: any;

  constructor(
    private modalCtrl: ModalController,
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.eventService.getTextAreaContent(this.content)
    .subscribe(
      res => {
        this.text_area_description = res.data.text_area_description;
      }
    );
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
